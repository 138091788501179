import {LoggingConfig} from './types';

export const shouldUseRemoteLogging = async (loggingConfig: LoggingConfig): Promise<boolean> => {
  // We enable remote logging for testing purposes if the feature flag is enabled
  if (window.AS.features.isFeatureEnabled('__enable_rollbar')) {
    return true;
  }
  // We enable remote logging if it is configured for the current environment
  return (
    window.AS.env.name !== undefined &&
    Object.prototype.hasOwnProperty.call(loggingConfig, window.AS.env.name)
  );
};
