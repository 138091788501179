import {LoggingConfig} from './types';

import {fetchJson} from '@/fetchJson';

const isLoggingConfig = (obj: any): obj is LoggingConfig => {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
};

export const getLoggingConfig = async (): Promise<LoggingConfig> => {
  try {
    const loggingConfig = await fetchJson('/apps/logging.json');
    if (isLoggingConfig(loggingConfig)) {
      return loggingConfig;
    }
  } catch {
    // nothing to do here
  }
  return {};
};
