import {processAppLayout} from './processAppLayout';
import type {
  CompositionConfig,
  ScreenLayoutDefinition,
  Relaxed,
  RelaxedCompositionConfig,
  ViewConfig,
} from './types';

/**
 * Converts a relaxed screen layout definition to the strict one.
 *
 * @param layout the relaxed layout definition to convert
 */
const convertRelaxedScreenLayout = (
  layout: Relaxed<ScreenLayoutDefinition> | undefined
): ScreenLayoutDefinition | undefined => {
  return typeof layout === 'string' ? {grid: layout} : layout;
};

/**
 * Converts a relaxed composition config to the strict one.
 *
 * @param config the relaxed composition config to convert
 */
export const convertRelaxedConfig = (config: RelaxedCompositionConfig): CompositionConfig => {
  const strictConfig: CompositionConfig = {
    views: {},
    auxApps: {},
    default: config.default,
  };
  Object.entries(config.views).forEach(([appId, viewConfig]) => {
    const newViewConfig: ViewConfig = {
      mainApps: processAppLayout(viewConfig.mainApps, app => {
        return typeof app === 'string' ? {name: app} : app;
      }),
    };
    if (viewConfig.auxApps) {
      newViewConfig.auxApps = processAppLayout(viewConfig.auxApps, app => {
        return typeof app === 'string' ? {name: app} : app;
      });
    }
    newViewConfig.layout = convertRelaxedScreenLayout(viewConfig.layout);
    strictConfig.views[appId] = newViewConfig;
  });
  strictConfig.auxApps = processAppLayout(config.auxApps, app => {
    return typeof app === 'string' ? {name: app} : app;
  });
  strictConfig.layout = convertRelaxedScreenLayout(config.layout);
  return strictConfig;
};
