import {AppLayout} from './types';

/**
 * Processes each app config in the layout with the provided mutation function.
 *
 * @param appLayout the layout to process
 * @param mutation the function to apply to each app config
 */
export const processAppLayout = <I, O>(
  appLayout: AppLayout<I>,
  mutation: (input: I, gridArea?: string) => O
): AppLayout<O> => {
  const res: AppLayout<O> = {};
  const {_unordered, ...gridApps} = appLayout;
  for (const [gridArea, app] of Object.entries(gridApps)) {
    res[gridArea] = mutation(app, gridArea);
  }
  if (_unordered) {
    res._unordered = _unordered.map(appId => mutation(appId));
  }
  return res;
};
