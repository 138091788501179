export const fetchJson = async (url: string): Promise<any> => {
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Pragma: 'no-cache',
      ['Cache-Control']: 'no-cache',
    },
  });
  if (response.status >= 400) {
    throw new Error(response.statusText);
  }
  return await response.json();
};
