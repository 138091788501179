import {initDummyErrorHandling} from '@alphasense/micro-app-error-handling';

import {initRemoteLogging} from './initRemoteLogging';

// initialize dummy error handling at module level for early access
initDummyErrorHandling(__VERSION, 'debug');

export const initLogging = async (): Promise<void> => {
  // initialize remote logging in the background
  initRemoteLogging().catch(window.Logger.error);
};
