import type {MicroAppVersion} from '@alphasense/micro-app-versioning';

/**
 * Default app config should only define some properties
 */
export type DefaultAppConfig = MicroAppVersion & Required<Pick<MicroAppVersion, 'version'>>;

export const defaultAppConfig: DefaultAppConfig = {
  version: 'current',
};
