import {fetchJson} from '@/fetchJson';
import {initRouting, isViewPath, ViewPath} from '@alphasense/micro-app-routing';
import {convertRelaxedConfig} from './convertRelaxedConfig';
import {processBackendData} from './processBackendData';
import type {AppLayout, ScreenLayoutDefinition, ViewConfig, CompositionConfig} from './types';

/** The runtime configuration of the composition of views and apps */
const compositionConfig: CompositionConfig = {
  views: {},
  auxApps: {},
};

/**
 * Initializes the composition config from backend data.
 */
const initCompositionConfig = async (): Promise<void> => {
  try {
    const data = await fetchJson('/apps/config.json');
    const relaxedConfig = processBackendData(data);
    const strictConfig = convertRelaxedConfig(relaxedConfig);
    Object.assign(compositionConfig, strictConfig);
    if (!compositionConfig.default) {
      // Despite the fact that composition config allows for undefined `default` view,
      // we still assign a default value in case none is provided by the backend
      // to ensure that some view is always loaded, no matter the location.
      // For simplicity, we just pick the first view (in the order they are defined)
      // from the `views` object.
      // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for...in#description
      // for more details on the order of keys in an object.
      compositionConfig.default = Object.keys(compositionConfig.views)[0];
    }
  } catch (error) {
    window.Logger.error('Unable to update apps config', error);
  }
};

/**
 * Fetches the path configs of views.
 */
const fetchPaths = async (): Promise<ViewPath[]> => {
  try {
    const paths = await fetchJson('/apps/paths.json');
    if (Array.isArray(paths) && paths.every(isViewPath)) {
      return paths;
    }
    window.Logger.error('Unexpected data in app paths');
  } catch (error) {
    window.Logger.error('Unable to get app paths', error);
  }
  return [];
};

/**
 * Initializes the routing table from the fetched paths.
 */
const initPaths = async (): Promise<void> => {
  const [paths] = await Promise.all([
    // fetching paths
    fetchPaths(),
    // and initializing routing at the same time
    initRouting(),
  ]);
  window.Routing.initRoutingTable(paths);
};

/**
 * Returns the configuration of the view with the given name.
 *
 * @param view the name of the view
 */
export const getViewConfigByName = (view: string): ViewConfig | undefined => {
  const config = compositionConfig.views[view];
  if (config) {
    return config;
  }
  if (compositionConfig.default) {
    return compositionConfig.views[compositionConfig.default];
  }
};

/**
 * Returns the layout of the system auxiliary apps.
 */
export const getSystemAuxApps = (): AppLayout => {
  return compositionConfig.auxApps;
};

/** Returns the default screen layout. */
export const getDefaultLayout = (): ScreenLayoutDefinition | undefined => {
  return compositionConfig.layout;
};

/** This promise is resolved when both composition config and routing table are initialized. */
export const mainRoutingInitialized = Promise.all([initCompositionConfig(), initPaths()]);
