import {getLoggingConfig} from './getLoggingConfig';
import {getReportLevel} from './getReportLevel';
import {shouldUseRemoteLogging} from './shouldUseRemoteLogging';

export const initRemoteLogging = async (): Promise<void> => {
  const loggingConfig = await getLoggingConfig();
  if (await shouldUseRemoteLogging(loggingConfig)) {
    const {initRollbarErrorHandling} = await import('@alphasense/micro-app-error-handling');
    initRollbarErrorHandling(__VERSION, getReportLevel(loggingConfig));
  }
};
