import {
  GetRegistryFunction,
  isMicroAppRegistry,
  isMicroAppVersion,
} from '@alphasense/micro-app-versioning';

import {fetchJson} from '../fetchJson';
import {defaultAppConfig} from './defaultAppConfig';
import {
  compileAppRegistry,
  compileAppVersion,
  mergeAppRegistries,
  MicroAppRegistryCompiled,
  MicroAppVersionCompiled,
} from './process';

const REGISTRY: MicroAppRegistryCompiled = new Map();

export const clearAppRegistry = (): void => {
  REGISTRY.clear();
};

export const updateAppRegistry = async (...getters: GetRegistryFunction[]): Promise<void> => {
  const compiledRegistries = await Promise.all(
    getters.map(async getRegistry => {
      try {
        const registry = await getRegistry();
        if (isMicroAppRegistry(registry)) {
          return compileAppRegistry(registry);
        }
        window.Logger.error('Invalid registry data:', registry);
      } catch (error) {
        window.Logger.error('Unable to get and compile registry:', error);
      }
      return new Map() as MicroAppRegistryCompiled;
    })
  );
  mergeAppRegistries(REGISTRY, ...compiledRegistries);
};

export const getAppVersion = async (appId: string): Promise<MicroAppVersionCompiled> => {
  let version = REGISTRY.get(appId);
  if (version === undefined) {
    // first check if the app provides its version info
    try {
      const appVersion = await fetchJson(`/apps/${appId}/version.json`);
      if (isMicroAppVersion(appVersion)) {
        version = compileAppVersion(appId, appVersion);
      }
    } catch {
      // ignoring errors to compile the default version
    }
    if (version === undefined) {
      version = compileAppVersion(appId, defaultAppConfig);
    }
    REGISTRY.set(appId, version);
  }
  return version;
};
