import {
  MicroAppRegistry,
  MicroAppUrlConfig,
  MicroAppVersion,
  MicroAppVersionMeta,
} from '@alphasense/micro-app-versioning';

import {defaultUrlConfig} from './defaultUrlConfig';

/**
 * A "compiled" Version that contains mandatory 'urls' attribute
 * with full URLs as a string (and at least one URL available).
 */
export type MicroAppVersionCompiled = MicroAppVersionMeta & {
  urls: string[];
};

/**
 * A "compiled" version of the app registry which features full URLs
 * for all the apps present.
 */
export type MicroAppRegistryCompiled = Map<string, MicroAppVersionCompiled>;

const isNonEmptyArray = <T>(arr: T[] | undefined): arr is T[] => {
  return arr !== undefined && arr.length > 0;
};

const doubleSlashRe = /\/{2,}/g;
const noFirstSlashRe = /^(?!\/)/;
const lastSlashRe = /\/+$/;

// TODO:
//  this is a temporary measure.
//  When each app version will have its own pathname (i.e. there's no more "current" versions)
//  we will be able to cache remote entry points to speed up page load.
const cacheInvalidationNonce = new Date().getTime().toString(32);

const compileAppUrl = (appId: string, version: string, urlConfig: MicroAppUrlConfig): string => {
  const pathname = `${urlConfig.basePath ?? defaultUrlConfig.basePath}/${
    urlConfig.appPath ?? appId
  }/${urlConfig.versionPath ?? version}/${
    urlConfig.entryPoint ?? defaultUrlConfig.entryPoint
  }?v=${cacheInvalidationNonce}`
    .replace(doubleSlashRe, '/')
    .replace(noFirstSlashRe, '/');
  if (!urlConfig.origin) {
    return pathname;
  }
  return `${urlConfig.origin.replace(lastSlashRe, '')}${pathname}`;
};

const compileAppUrls = (appId: string, versionConfig: MicroAppVersion): string[] => {
  const urls = isNonEmptyArray(versionConfig.urls) ? versionConfig.urls : [{}];
  return urls.map(url => {
    return typeof url === 'string' ? url : compileAppUrl(appId, versionConfig.version, url);
  });
};

export const compileAppVersion = (
  appId: string,
  versionConfig: MicroAppVersion
): MicroAppVersionCompiled => {
  return {...versionConfig, urls: compileAppUrls(appId, versionConfig)};
};

export const compileAppRegistry = (
  registryFromServer: MicroAppRegistry
): MicroAppRegistryCompiled => {
  return Object.keys(registryFromServer).reduce<MicroAppRegistryCompiled>((registry, appId) => {
    registry.set(appId, compileAppVersion(appId, registryFromServer[appId]));
    return registry;
  }, new Map());
};

export const mergeAppRegistries = (
  mergeInto: MicroAppRegistryCompiled,
  ...registries: MicroAppRegistryCompiled[]
): MicroAppRegistryCompiled => {
  return registries.reduce<MicroAppRegistryCompiled>((merged, registry) => {
    registry.forEach((version, appId) => {
      merged.set(appId, version);
    });
    return mergeInto;
  }, mergeInto);
};
