import {AppUrlGetter, installRemoteLoaders} from '@alphasense/micro-app-loading';

import {getAppVersion, updateAppRegistry} from '@/app-versions';
import {getClientRegistry} from '@/getClientRegistry';
import {getServerRegistry} from '@/getServerRegistry';

const getAppUrls: AppUrlGetter = async appId => {
  const version = await getAppVersion(appId);
  return version.urls;
};

const init = async (): Promise<void> => {
  // Have to synchronously install the loaders first
  // to be able to use any shared scope within the shell.
  installRemoteLoaders(getAppUrls);
  await updateAppRegistry(getServerRegistry, getClientRegistry);
};

export const appRegistryInitialized = init();
