import {MicroAppUrlConfig} from '@alphasense/micro-app-versioning';

/**
 * Default URL config should only define some properties
 */
export type DefaultUrlConfig = Pick<MicroAppUrlConfig, 'basePath' | 'entryPoint'>;

export const defaultUrlConfig: DefaultUrlConfig = {
  // uses the same origin
  basePath: '/apps',
  // uses the 'version' attribute as versionPath
  entryPoint: 'remoteEntry.js',
};
