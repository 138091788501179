/**
 * This file contains type guards for the data we expect to get from the backend
 * as composition configuration.
 */
import type {
  AppConfig,
  AppLayout,
  BackendCompositionConfig,
  ScreenLayoutDefinition,
  ViewConfig,
} from './types';

export const isAppConfig = (data: any): data is AppConfig => {
  return (
    typeof data === 'string' ||
    (typeof data === 'object' && data !== null && typeof data.name === 'string')
  );
};

export const isAppLayout = (data: any): data is AppLayout => {
  if (
    typeof data !== 'object' ||
    data === null ||
    Array.isArray(data) ||
    Object.keys(data).length === 0
  ) {
    return false;
  }
  return Object.entries(data).every(([gridArea, appConfig]) => {
    if (gridArea === '_unordered') {
      return Array.isArray(appConfig) && appConfig.every(isAppConfig);
    }
    return isAppConfig(appConfig);
  });
};

export const isScreenLayoutDefinition = (data: any): data is ScreenLayoutDefinition => {
  return (
    typeof data === 'string' ||
    (typeof data === 'object' && data !== null && typeof data.grid === 'string')
  );
};

export const isViewConfig = (data: any): data is ViewConfig => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  if (!isAppLayout(data.mainApps)) {
    return false;
  }
  if (data.auxApps !== undefined && !isAppLayout(data.auxApps)) {
    return false;
  }
  if (data.layout !== undefined && !isScreenLayoutDefinition(data.layout)) {
    return false;
  }
  return true;
};

export const isLegacyViewConfig = (data: any): data is {mainApp: string; auxApps?: string[]} => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  if (typeof data.mainApp !== 'string' || !data.mainApp) {
    return false;
  }
  if (data.auxApps !== undefined) {
    return (
      Array.isArray(data.auxApps) &&
      data.auxApps.every((app: any) => {
        return typeof app === 'string';
      })
    );
  }
  return true;
};

export const isBackendCompositionConfig = (data: any): data is BackendCompositionConfig => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  if (typeof data.default !== 'string' && data.default !== undefined) {
    return false;
  }
  // relaxed config
  if (data.views !== undefined && typeof data.views === 'object' && data.views !== null) {
    if (data.auxApps !== undefined && !isAppLayout(data.auxApps)) {
      return false;
    }
    return Object.values(data.views).every(isViewConfig);
  }
  // legacy config
  if ('apps' in data && typeof data.apps === 'object' && data.apps !== null) {
    if (data.auxApps !== undefined && !Array.isArray(data.auxApps)) {
      return false;
    }
    return Object.values(data.apps).every(isLegacyViewConfig);
  }
  return false;
};
