import {getPath, getRedirectPath, matchPath} from '@alphasense/micro-app-routing';
import type {GoToParams} from '@alphasense/micro-app-routing-lib';
import type {Location} from 'history';
import {ViewConfig} from './types';

import {getViewConfigByName} from './compositionConfig';

/** The key is named such for backward compatibility with ui-webapp */
const returnToKey = 'entryUrl';

/** An aggregation of entities required to switch to a new view */
export type ViewDescriptor = {
  /** Name of the view to load */
  view?: string;

  /** Configuration of the view to load */
  config?: ViewConfig;

  /** Optional redirection to perform after switching to the view */
  redirect?: GoToParams;
};

/**
 * Returns the view name and configuration for the given location.
 * Optionally returns a redirection to perform after switching to the view.
 *
 * @param location the location to get the view descriptor for
 */
export const getViewDescriptor = async (location: Location): Promise<ViewDescriptor> => {
  const res: ViewDescriptor = {};
  const authenticated = await window.Auth.isAuthenticated();
  const originalAppPath = getPath(location);
  const appPath = getRedirectPath(originalAppPath, authenticated);
  if (!appPath) {
    return res;
  }
  res.view = appPath.view;
  res.config = getViewConfigByName(appPath.view);
  if (originalAppPath !== appPath) {
    // switching apps, because the app for this path
    // cannot be loaded with current authentication state
    res.redirect = appPath.path;
    if (appPath.saveReturnUrl) {
      // if the path is configured to save return url, do so
      localStorage.setItem(returnToKey, window.Routing.createHref(location));
    } else {
      // otherwise, if there is a return url which matches current app, use it
      try {
        const returnTo = localStorage.getItem(returnToKey);
        localStorage.removeItem(returnToKey);
        if (returnTo) {
          const returnToPath = window.Routing.parsePath(returnTo);
          if (matchPath(appPath, returnToPath)) {
            res.redirect = returnToPath;
          }
        }
      } catch {}
    }
  } else if (!matchPath(appPath, location)) {
    // if current pathname does not match supported paths,
    // change it to the default path for this app
    res.redirect = appPath.path;
  }
  return res;
};
