import {AppLayout} from './types';

/**
 * Converts an AppLayout with promises in grid areas and _unordered
 * to an AppLayout with the results of those promises resolved.
 */
export const awaitAppLayout = async <T>(
  appLayout: AppLayout<Promise<T>>
): Promise<AppLayout<T>> => {
  const res: AppLayout<T> = {};
  const {_unordered, ...gridApps} = appLayout;
  for (const [gridArea, app] of Object.entries(gridApps)) {
    res[gridArea] = await app;
  }
  if (_unordered) {
    res._unordered = await Promise.all(_unordered);
  }
  return res;
};
