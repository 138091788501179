import {isBackendCompositionConfig} from './isBackendCompositionConfig';
import type {AppConfig, AppLayout, RelaxedCompositionConfig, ViewConfig} from './types';

/** Converts a legacy aux app list to the new app layout format. */
const convertLegacyAuxApps = (auxApps: string[]): AppLayout => {
  return auxApps.reduce(
    (res, appName) => {
      res._unordered.push({name: appName});
      return res;
    },
    {_unordered: <Array<AppConfig>>[]}
  ) as AppLayout;
};

/**
 * Type-checks composition configuration data received from backend
 * and converts the legacy format to the new one if needed.
 *
 * @param config the composition configuration data received from backend
 */
export const processBackendData = (config: any): RelaxedCompositionConfig => {
  const relaxedConfig: RelaxedCompositionConfig = {
    views: {},
    auxApps: {},
  };
  if (!isBackendCompositionConfig(config)) {
    window.Logger.critical('Invalid composition config', {config});
    return relaxedConfig;
  }
  if (typeof config.default === 'string') {
    relaxedConfig.default = config.default;
  }
  if (!('apps' in config)) {
    relaxedConfig.views = config.views ?? {};
    relaxedConfig.auxApps = config.auxApps ?? {};
    relaxedConfig.layout = config.layout;
  } else {
    Object.entries(config.apps ?? {}).forEach(([appId, viewConfig]) => {
      const newViewConfig: ViewConfig = {
        mainApps: {_unordered: [{name: viewConfig.mainApp}]} as AppLayout,
      };
      if (Array.isArray(viewConfig.auxApps)) {
        newViewConfig.auxApps = convertLegacyAuxApps(viewConfig.auxApps);
      }
      relaxedConfig.views[appId] = newViewConfig;
    });
    if (Array.isArray(config.auxApps)) {
      relaxedConfig.auxApps = convertLegacyAuxApps(config.auxApps);
    }
  }
  return relaxedConfig;
};
