import '@/logging';

import {initEnv} from '@alphasense/micro-app-env';

import './index.scss';
import {appRegistryInitialized} from './init-versions';
import {initLogging} from './logging';
import {mainRoutingInitialized} from './main-routing';

const boot = async (): Promise<void> => {
  await initEnv();
  await initLogging();
  const primaryTasks = [
    //
    appRegistryInitialized,
    mainRoutingInitialized,
    import('./init-mq'),
    import('./init-auth'),
    import('./init-features').then(({featuresInitialized}) => featuresInitialized),
    import('./init-tracking').then(({trackingInitialized}) => trackingInitialized),
    // preload modules for the next step
    import('./load-apps'),
  ];
  // auxiliary tasks can be performed in background
  const auxiliaryTasks: Promise<any>[] = [
    // there should be any tasks that run in parallel
    // with the primary ones, but are not strictly required
    // for the main app loading
    import('./init-styles'),
  ];
  // initialize the framework
  await Promise.all(primaryTasks);
  // load the apps
  const {loadApps} = await import('./load-apps');
  await loadApps();
  // wait for the auxiliary tasks at the end
  await Promise.all(auxiliaryTasks);
};

boot().catch(window.Logger.error);
